import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {login, loginFailure, loginSuccess} from '../actions/user.actions';
import {Router} from '@angular/router';
import {UserService} from '../user/services/user.service';

@Injectable()
export class UserEffects {

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(login),
      switchMap(action => this.userService.login(action.username, action.password).pipe(
        map(user => loginSuccess({user})),
        catchError( error => of(loginFailure({error}))
        )
      ))
    );
  });


  constructor(
    private actions$: Actions,
    private  router: Router,
    private userService: UserService
  ) {
  }
}
