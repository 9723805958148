import {Component, OnInit} from '@angular/core';

import {Event} from '../models/event';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {getEvent, getEventsLoading} from '../selectors/events.selectors';
import {State} from '../reducers/events.reducer';
import {Observable} from 'rxjs';
import {loadEvents, registerEvent, unRegisterEvent} from '../actions/events.actions';
import {LocationPipe} from '../shared/location.pipe';
import {User} from '../../models/user';
import {getUser} from '../../selectors/user.selectors';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css']
})
export class EventDetailsComponent implements OnInit {
  event$: Observable<Event>;
  loading$: Observable<boolean>;
  user$: Observable<User>;
  now = new Date();

  constructor(
    private store: Store<State>,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  mapsSelector(location) {
    const locationPipe = new LocationPipe();
    const locationString = locationPipe.transform(location, true);

    if ((navigator.platform.indexOf('iPhone') !== -1) ||
      (navigator.platform.indexOf('iPod') !== -1) ||
      (navigator.platform.indexOf('iPad') !== -1)) {
      window.open('maps://maps.google.com/maps?q=' + locationString);
    } else {
      window.open('https://maps.google.com/maps?q=' + locationString);
    }
  }

  register(id: string, user: User) {
    this.store.dispatch(registerEvent({ id, username: user.name, password: user.password }));
  }

  unRegister(id: string, user: User) {
    this.store.dispatch(unRegisterEvent({ id, username: user.name, password: user.password }));
  }

  ngOnInit() {
    const params = this.route.snapshot.paramMap;
    const id = params.get('id');

    this.event$ = this.store.pipe(select(getEvent, {id}));
    this.loading$ = this.store.pipe(select(getEventsLoading));
    this.user$ = this.store.pipe(select(getUser));
    this.store.dispatch(loadEvents());
  }
}
