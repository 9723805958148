import { createAction, props } from '@ngrx/store';

export enum UserSettingsActionTypes {
  SetSection = '[UserSettings] Set Section',
  SetAllSections = '[UserSettings] Set All Sections'
}

export const setSection = createAction(
  UserSettingsActionTypes.SetSection,
  props<{ section: string }>()
);

export const setAllSections = createAction(
  UserSettingsActionTypes.SetAllSections
);
