import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {catchError, map, retry} from 'rxjs/operators';
import {Event} from '../models/event';
import {EventFactory} from './event-factory';
import {EventRaw, EventRegistrationRaw} from '../models/event-raw';
import {SettingsService} from '../../services/settings.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(
    private settingsService: SettingsService,
    private http: HttpClient
  ) {
  }

  private static errorHandler(error: HttpErrorResponse): Observable<any> {
    console.error('Error occurred:', error);
    return throwError(error);
  }

  private contains(array: Event[], event: Event): boolean {
    if (!array) {
      return false;
    }
    return array.filter(e => e.id === event.id).length > 0;
  }

  private getAllEvents(section: string = null): Observable<Event[]> {
    const url = section
      ? `${this.settingsService.settings.eventApiUrl}/getAllFutureEvents?organisation=${section}`
      : `${this.settingsService.settings.eventApiUrl}/getAllFutureEvents`;

    return this.http.get<EventRaw[]>(url)
      .pipe(
        retry(3),
        map(eventsRaw => eventsRaw.map(e => EventFactory.fromEventRaw(e))),
        catchError(EventService.errorHandler)
      );
  }

  private getUsersEvents(username: string, password: string): Observable<Event[]> {
    if (!username || !password) {
      return of();
    }
    const url = `${this.settingsService.settings.eventApiUrl}/getMyFutureEvents`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa(`${username}:${password}`)
      })
    };

    return this.http.get<EventRegistrationRaw[]>(url, httpOptions)
      .pipe(
        retry(3),
        map(eventsRaw => eventsRaw.map(e => EventFactory.fromEventRegistrationRaw(e))),
        catchError(EventService.errorHandler)
      );
  }

  get(username: string, password: string, section: string = null): Observable<Event[]> {
    if (
      username === undefined
      || username.length < 1
      || password === undefined
      || password.length < 1
    ) {
      return this.getAllEvents(section);
    }
    return forkJoin([
      this.getAllEvents(section),
      this.getUsersEvents(username, password)
    ]).pipe(
      map(results => {
        const allEvents = results[0];
        const userEvents = results[1];

        return [
          ...userEvents,
          ...allEvents.filter(event => !this.contains(userEvents, event))
        ];
      })
    );
  }

  register(id: string, username, password): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa(`${username}:${password}`)
      })
    };

    return this.http.get<any>(
      `${this.settingsService.settings.eventApiUrl}/registerEvent?id=${id}`,
      httpOptions
    )
      .pipe(
        retry(3),
        catchError(EventService.errorHandler)
      );
  }

  unRegister(id: string, username: string, password: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa(`${username}:${password}`)
      })
    };

    return this.http.get<any>(
      `${this.settingsService.settings.eventApiUrl}/unRegisterEvent?id=${id}`,
      httpOptions
    )
      .pipe(
        retry(3),
        catchError(EventService.errorHandler)
      );
  }

}
