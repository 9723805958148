import { Action, createReducer, on } from '@ngrx/store';

import { User } from '../models/user';
import { login, loginSuccess, loginFailure, logout } from '../actions/user.actions';

export const userFeatureKey = 'user';

export interface State {
  user: User;
  loggedIn: boolean;
  loggingIn: boolean;
  loginFailure: string;
}

export const initialState: State = {
  user: null,
  loggedIn: false,
  loggingIn: false,
  loginFailure: ''
};

const userReducer = createReducer(
  initialState,
  on(login, () => ({...initialState, loggingIn: true})),
  on(loginSuccess, (state, {user}) => ({...state, user, loggingIn: false, loggedIn: true})),
  on(loginFailure, (state, {error}) => ({...initialState, loginFailure: error})),
  on(logout, () => ({...initialState}))
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}
