import {createAction, props} from '@ngrx/store';
import { Event } from '../models/event';
import {HttpErrorResponse} from '@angular/common/http';

export const loadEvents = createAction(
  '[Events] Load Events'
);

export const loadEventsSuccess = createAction(
  '[Events] Load Events Success',
  props<{ events: Event[] }>()
);

export const loadEventsFailure = createAction(
  '[Events] Load Events Failure',
  props<{ error: HttpErrorResponse }>()
);

export const loadEvent = createAction(
  '[Events] Load Event',
  props<{ id: string }>()
);

export const loadEventSuccess = createAction(
  '[Events] Load Event Success',
  props<{ event: Event }>()
);

export const loadEventFailure = createAction(
  '[Events] Load Event Failure',
  props<{ error: HttpErrorResponse }>()
);

export const registerEvent = createAction(
  '[Events] Register Event',
  props<{ id: string; username: string; password: string }>()
);

export const registerEventSuccess = createAction(
  '[Events] Register Event Success'
);

export const registerEventFailure = createAction(
  '[Events] Register Event Failure'
);

export const unRegisterEvent = createAction(
  '[Events] Unregister Event',
  props<{ id: string; username: string; password: string }>()
);

export const unRegisterEventSuccess = createAction(
  '[Events] Unregister Event Success'
);

export const unRegisterEventFailure = createAction(
  '[Events] Unregister Event Failure'
);
