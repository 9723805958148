<mat-toolbar *ngIf="router.url === '/events'" color="secondary">
  <div class="centeredLogo">
    <img class="logo" src="/assets/stv-logo.svg" alt="logo">
  </div>
  <mat-icon class="icon" [routerLink]="'/userSettings'">settings</mat-icon>
</mat-toolbar>

<mat-toolbar *ngIf="router.url !== '/events'" color="secondary">
  <mat-icon
    class="icon"
    *ngIf="router.url != '/events'"
    [routerLink]="'/events'"
  >arrow_back_ios
  </mat-icon>
  <span *ngIf="router.url.indexOf('/events/') >= 0" class="title">
    Veranstaltung
  </span>
  <span *ngIf="router.url === '/userSettings'" class="title">
    Einstellungen
  </span>
</mat-toolbar>
