import {Pipe, PipeTransform} from '@angular/core';
import {Location} from '../models/event';

@Pipe({
  name: 'location'
})
export class LocationPipe implements PipeTransform {
  transform(location: Location, includeName: boolean): string {
    const city = [location.zip, location.city]
      .filter(curr => curr && curr.length > 0)
      .reduce((acc, curr) => `${acc} ${curr}`, '')
      .trim();
    const parts = includeName
      ? [location.name, location.street, city]
      : [location.street, city];
    const filtered = parts.filter(curr => curr && curr.length > 0);
    const trimmed = filtered.map(curr => `${curr}`.trim());

    return trimmed.length > 0
      ? trimmed.reduce((acc, curr) => `${acc}, ${curr}`)
      : null;
  }
}
