<mat-card>
  <mat-card-title>
    Account
  </mat-card-title>
  <mat-card-content>
    <form
      *ngIf="!(loggedIn$ | async)"
      [formGroup]="loginForm" (ngSubmit)="onSubmit()"
    >
      <mat-error *ngIf="loginFailure$ | async">
        Login ist fehlgeschlagen
      </mat-error>
      <mat-form-field class="full-width-input">
        <input matInput placeholder="Email" formControlName="username" required>
        <mat-error>
          Email ist erforderlich
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <input matInput type="password" placeholder="Passwort" formControlName="password" required>
        <mat-error>
          Passwort ist erforderlich
        </mat-error>
      </mat-form-field>
      <div style="text-align: end; width: 100%">
        <button mat-button color="primary">LOGIN</button>
      </div>
    </form>

    <div *ngIf="user$ | async as user">
      Sie sind mit folgender E-Mail Adresse angemeldet:<br/>
      {{ user.name }}
      <div style="text-align: end; width: 100%">
        <button (click)="logout()" color="primary" mat-button>LOGOUT</button>
      </div>


    </div>

  </mat-card-content>
</mat-card>
<app-progress *ngIf="loggingIn$ | async"></app-progress>
