<div class="details" *ngIf="event$ | async as event">

  <h1>{{ event.title }}</h1>

  <ng-container *ngIf="event.logo?.url && event.logo.url.length > 0">
    <img [src]="event.logo.url" alt="logo" width="100%">
  </ng-container>

  <div
    *ngIf="event.location?.name || event.location?.zip || event.location?.city || event.location?.street"
    class="detailsSection"
  >
    <a
      *ngIf="event.location?.name as location_name"
      [routerLink]=""
      (click)="mapsSelector(event.location)"
    >
      {{ location_name }}<br/>
    </a>
    <a
      *ngIf=" event.location | location: false as location_address"
      [routerLink]=""
      (click)="mapsSelector(event.location)"
    >
      {{ location_address }}
    </a>
  </div>

  <div
    *ngIf="event.schedule | schedule as schedule"
    class="detailsSection"
  >
    {{ schedule }}<br/>
  </div>

  <div *ngIf="event.host.name || event.host.email">
    <ng-container *ngIf="event.host.name">
      {{ event.host.name }} <br/>
    </ng-container>
    <ng-container *ngIf="event.host.email">
      {{ event.host.email }} <br/>
    </ng-container>
  </div>

  <div
    *ngIf="event.price || event.membersPrice"
    class="detailsSection"
  >
    <ng-container *ngIf="event.price">
      Kosten: CHF {{ event.price }}<br/>
    </ng-container>
    <ng-container *ngIf="event.membersPrice && event.membersPrice > 0">
      Kosten für Mitglieder: CHF {{ event.membersPrice }}<br/>
    </ng-container>
  </div>

  <div
    *ngIf="event.description"
    class="detailsSection"
  >
    <div [innerHTML]="event.description"></div>
  </div>

  <div
    *ngIf="event.documents && event.documents.length > 0"
    class="detailsSection"
  >
    <ng-container *ngFor="let document of event.documents">
      <a target="_blank" rel="noopener noreferrer" [href]="document.url">{{ document.name }}</a><br/>
    </ng-container>
  </div>

  <div class="registration" *ngIf=" user$ | async as user">
    <div>
      <div
        *ngIf="event.registrationStatus === 'registered'"
        class="registration-status-item"
      >
        <mat-icon style="color: green">check</mat-icon>
        <span style="color: green">Ich nehme teil!</span>
      </div>
      <div
        *ngIf="event.title === 'ohne Lokalität' || event.registrationStatus === 'unregistered'"
        class="registration-status-item"
      >
        <mat-icon>close</mat-icon>
        <span>Ich habe mich abgemeldet!</span>
      </div>
    </div>
    <div class="buttons">
      <button
        *ngIf="event.registrationStatus !== 'registered'"
        [disabled]="event.registrationDeadline && event.registrationDeadline < now"
        mat-raised-button
        color="primary"
        (click)="register(event.id, user)"
      >Anmelden
      </button>
      <button
        *ngIf="event.registrationStatus === 'registered'"
        mat-raised-button
        color="primary"
        (click)="unRegister(event.id, user)"
      >Abmelden
      </button>
    </div>
  </div>

  <div class="registration" *ngIf="!(user$ | async)">
    <div>
    </div>
    <div class="buttons">
      <button disabled mat-raised-button color="primary">Anmelden</button>
    </div>
  </div>

  <div
    style="text-align: end"
    *ngIf="event.registrationDeadline | deadline as deadline"
    class="deadlineSection"
  >
    Anmeldeschluss: {{ deadline }}
  </div>

</div>
<app-progress *ngIf="loading$ | async"></app-progress>
