import { Action} from '@ngrx/store';

import {UserSettingsActionTypes} from '../actions/usersettings.actions';

export const userSettingsFeatureKey = 'userSettings';

export interface State {
  section: string;
}

export const initialState: State = {section: ''};

const userSettingsReducer = (state = initialState, action ) => {
  switch (action.type) {
    case UserSettingsActionTypes.SetSection:
      return  ({...initialState, section: action.section });

    case UserSettingsActionTypes.SetAllSections:
      return {...initialState};

    default:
      return state;
  }
};

export function reducer(state: State | undefined, action: Action) {
  return userSettingsReducer(state, action);
}
