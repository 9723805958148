import {Component, OnInit} from '@angular/core';
import {combineLatest, Observable, Subject} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import {State} from '../reducers/events.reducer';
import {loadEvents} from '../actions/events.actions';
import {Event} from '../models/event';
import {getAllEvents, getEventsLoading} from '../selectors/events.selectors';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.css']
})
export class EventListComponent implements OnInit {

  constructor(
    private store: Store<State>
  ) {
  }
  events$: Observable<Event[]>;
  loading$: Observable<boolean>;
  keyUp$ = new Subject<string>();

  private static compare(a: Event, b: Event): number {
    if (!a.schedule?.fromDate || !b.schedule?.fromDate) {
      return 0;
    }
    if (a.schedule?.fromDate > b.schedule?.fromDate) {
      return 1;
    }
    return -1;
  }

  contains(event: Event, term: string): boolean {
    return event.title?.toLowerCase().indexOf(term) >= 0
      || event.description?.toLowerCase().indexOf(term) >= 0
      || event.host?.name?.toLowerCase().indexOf(term) >= 0
      || event.host?.email?.toLowerCase().indexOf(term) >= 0
      || event.location?.name?.toLowerCase().indexOf(term) >= 0
      || event.location?.street?.toLowerCase().indexOf(term) >= 0
      || event.location?.zip?.toLowerCase().indexOf(term) >= 0
      || event.location?.city?.toLowerCase().indexOf(term) >= 0
      ;
  }

  private filterEvents(events: Event[], term: string): Event[] {
    return events.filter(cur => this.contains(cur, term.toLowerCase()));
  }

  ngOnInit(): void {
    this.events$ = combineLatest([
      this.store.pipe(select(getAllEvents)),
      this.keyUp$.pipe(startWith(''))
    ]).pipe(
      map(([events, term]) => this.filterEvents(events, term)),
      map(events => events.sort((a, b) =>  EventListComponent.compare(a, b)))
    );
    this.loading$ = this.store.pipe(select(getEventsLoading));
    this.store.dispatch(loadEvents());
  }
}
