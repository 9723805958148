import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'deadline'
})
export class DeadlinePipe implements PipeTransform {
  transform(date: Date, ...args: unknown[]): string {
    if (!date) {
      return '';
    }
    return `${date.toLocaleDateString()}`;
  }
}
