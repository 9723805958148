<div>
  <ng-container *ngIf="events$ | async as events">
    <mat-form-field class="full-width-input" appearance="outline">
      <mat-icon matPrefix>search</mat-icon>
      <input
        matInput placeholder="Filter"
        (keyup)="keyUp$.next($event.target.value)"
      >
    </mat-form-field>
    <app-event-list-item
      *ngFor="let event of events"
      [event]="event"
      [routerLink]="event.id"
    ></app-event-list-item>
  </ng-container>
  <app-progress *ngIf="loading$ | async"></app-progress>
</div>
