<mat-card>
  <mat-card-title>
    Fachgruppen und Sektionen
  </mat-card-title>
  <mat-card-content>
    <p>
      Bitte eine Fachgruppe oder Sektion auswählen
    </p>
    <mat-form-field class="full-width-input" appearance="outline">
      <mat-icon matPrefix>search</mat-icon>
      <input
        matInput placeholder="Filter"
        (input)="setFilter($event)"
      >
      <mat-error>
        Holla die Waldfee, keine Suchresultate gefunden...
      </mat-error>
    </mat-form-field>

    <mat-checkbox
      [checked]="!(section$ | async)"
      (change)="setAllSections()"
      color="primary"
    >Alle </mat-checkbox>
    <mat-checkbox
      *ngFor="let section of sections"
      [checked]="section.id === (section$ | async)"
      (change)="setSection(section.id)"
      color="primary"
    >
      <div>
        <span class="wrap-mat-checkbox-label">
                {{ section.name }}
            </span>
      </div>
    </mat-checkbox>

  </mat-card-content>
</mat-card>
