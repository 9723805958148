import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {getLoggedIn} from '../selectors/user.selectors';
import {logout} from '../actions/user.actions';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {
  loggedIn$: Observable<boolean>;

  constructor(
    private store: Store<any>,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.loggedIn$ = this.store.pipe(select(getLoggedIn));
  }

  logout(): void {
    this.store.dispatch(logout());
  }

}
