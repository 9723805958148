import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {of} from 'rxjs';
import {map, catchError, switchMap, withLatestFrom, tap} from 'rxjs/operators';
import {EventService} from '../services/event.service';
import {
  loadEvents,
  loadEventsSuccess,
  loadEventsFailure,
  registerEvent,
  registerEventSuccess,
  registerEventFailure,
  unRegisterEvent, unRegisterEventFailure, unRegisterEventSuccess
} from '../actions/events.actions';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';

@Injectable()
export class EventsEffects {

  loadEvents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadEvents),
      withLatestFrom(this.store$),
      switchMap(([, storeState]) =>
        this.eventService.get(
          storeState.user.user?.name,
          storeState.user.user?.password,
          storeState.userSettings.section
        ).pipe(
          map(events => loadEventsSuccess({ events })),
          catchError(error => of(loadEventsFailure({error})))
        )
      )
    );
  });

  registerEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(registerEvent),
      switchMap(action =>
        this.eventService.register(action.id, action.username, action.password).pipe(
          map(() => registerEventSuccess()),
          tap(() => this.router.navigate(['/'])),
          catchError(() => of(registerEventFailure()))
        )
      )
    );
  });

  unRegisterEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(unRegisterEvent),
      switchMap(action =>
        this.eventService.unRegister(action.id, action.username, action.password).pipe(
          map(() => unRegisterEventSuccess()),
          tap(() => this.router.navigate(['/'])),
          catchError(() => of(unRegisterEventFailure()))
        )
      )
    );
  });

  constructor(
    private router: Router,
    private actions$: Actions,
    private eventService: EventService,
    private store$: Store<any>
  ) {}
}
