import {Document, Event, Host, Schedule, Location, Logo} from '../models/event';
import {DocumentRaw, EventRaw, EventRegistrationRaw} from '../models/event-raw';

export class EventFactory {

  static hostFromRaw(e: EventRaw): Host {
    return {
      name: e.organisation?.bezeichnung?.de,
      email: e.kontakt_email
    };
  }

  static dateFromRaw(raw: string): Date {
    if (raw?.length !== 10) {
      return null;
    }
    const day = Number(raw.slice(0, 2));
    const month = Number(raw.slice(3, 5));
    const year = Number(raw.slice(6, 10));

    if (
      !Number.isInteger(day)
      || !Number.isInteger(month)
      || !Number.isInteger(year)) {
      return null;
    }
    return new Date(year, month - 1, day);
  }

  static timeFromRaw(raw: string): Date {
    if (raw?.length !== 5) {
      return null;
    }
    const hour = Number(raw.slice(0, 2));
    const minute = Number(raw.slice(3, 5));
    if (
      !Number.isInteger(hour)
      || !Number.isInteger(minute)) {
      return null;
    }
    const date = new Date(0);

    date.setHours(hour);
    date.setMinutes(minute);
    return date;
  }

  static scheduleFromRaw(e: EventRaw): Schedule {
    const schedule = new Schedule();

    schedule.fromDate = this.dateFromRaw(e.startDate);
    schedule.toDate = this.dateFromRaw(e.endDate);
    schedule.fromTime = this.timeFromRaw(e.zeit_von);
    schedule.toTime = this.timeFromRaw(e.zeit_bis);
    return schedule;
  }

  static locationFromRaw(e: EventRaw): Location {
    return {
      name: e.lokalitaet,
      street: e.lokalitaet_strasse,
      zip: e.lokalitaet_plz,
      city: e.lokalitaet_ort
    };
  }

  static logoFromRaw(e: EventRaw): Logo {
    if (!e.logoUrl || e.logoUrl.length < 1) {
      return null;
    }
    return {url: e.logoUrl};
  }

  static documentsFromRaw(d: DocumentRaw[]): Document[] {
    return !d || d.length < 1
      ? null
      : d.map(document => ({
        name: document.name,
        url: document.url
      })
    );
  }

  static fromEventRaw(e: EventRaw): Event {
    return {
      id: e.id,
      title: e.titles?.de,
      description: e.descriptions?.de,
      host: this.hostFromRaw(e),
      schedule: this.scheduleFromRaw(e),
      location: this.locationFromRaw(e),
      logo: this.logoFromRaw(e),
      documents: this.documentsFromRaw(e.documents),
      price: e.kosten,
      membersPrice: e.kostenMitglieder,
      registrationStatus: 'none',
      registrationDeadline: this.dateFromRaw(e.anmeldeschluss)
    };
  }

  static registrationStatusFromRaw(e: EventRegistrationRaw) {
    switch (e.registerInfo.status) {
      case 'angemeldet':
        return 'registered';

      case 'abgemeldet':
        return 'unregistered';

      default:
        return 'none';
    }
  }

  static fromEventRegistrationRaw(e: EventRegistrationRaw): Event {
    return {
      ...this.fromEventRaw(e.eventInfo),
      registrationStatus: this.registrationStatusFromRaw(e)
    };
  }
}
