import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-settings',
  templateUrl: './userSettings.component.html',
  styleUrls: ['./userSettings.component.css']
})
export class UserSettingsComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {}

}
