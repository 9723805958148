import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromEvent from '../reducers/events.reducer';

export const selectEventState = createFeatureSelector<fromEvent.State>(
  fromEvent.eventsFeatureKey
);

export const getEventsLoading = createSelector(selectEventState, state => state.loading);

export const getAllEvents = createSelector(selectEventState, state => state.events);

export const getEvent = createSelector(selectEventState, (state, props) => {
  const matchingId =  state.events.filter(e => e.id === props.id);

  return matchingId.length > 0 ? matchingId[0] : null;
});
