import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {Action, ActionReducer, MetaReducer, StoreModule} from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';

import { environment } from '../environments/environment';
import {SettingsInitializerService} from './services/settings-initializer.service';

import { AppRoutingModule } from './app-routing.module';
import {MaterialModule} from './material/material.module';
import {HelperModule} from './helper/helper.module';
import {EventsModule} from './events/events.module';
import {UserModule} from './user/user.module';

import { AppComponent } from './app.component';
import { ToolbarComponent } from './toolbar/toolbar.component';

import {AppEffects} from './app.effects';

import {UserEffects} from './effects/user.effects';
import { reducer as userReducer } from './reducers/user.reducer';
import { reducer as userSettingsReducer } from './reducers/usersettings.reducer';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SectionSettingsComponent } from './section-settings/section-settings.component';

export function initSettings(settingsInitializerService: SettingsInitializerService) {
  return () => settingsInitializerService.initializeSettings();
}

// console.log all actions
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    console.log('state', state);
    console.log('action', action);

    return reducer(state, action);
  };
}

export function persistStateReducer(reducer: ActionReducer<any>) {
  const localStorageKey = '_userSettings';

  return (state: any | undefined, action: Action) => {
    if (state === undefined) {
      const persisted = localStorage.getItem(localStorageKey);

      if (!persisted) {
        return reducer(state, action);
      }
      const deserialized = JSON.parse(persisted);
      const { user, userSettings } = deserialized;

      return { user, userSettings };
    }
    const newState = reducer(state, action);

    localStorage.setItem(localStorageKey, JSON.stringify(newState));
    return newState;
  };
}

export const metaReducers: MetaReducer<any>[] = [persistStateReducer];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    StoreModule.forRoot(
      {user: userReducer, userSettings: userSettingsReducer},
      {metaReducers}
      ),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    EffectsModule.forRoot([AppEffects, UserEffects]),
    HelperModule,
    EventsModule,
    UserModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    AppComponent,
    ToolbarComponent,
    UserSettingsComponent,
    SectionSettingsComponent
  ],
  providers: [
    {provide: APP_INITIALIZER, useFactory: initSettings, deps: [SettingsInitializerService], multi: true}
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

