import {Pipe, PipeTransform} from '@angular/core';
import {Schedule} from '../models/event';

@Pipe({
  name: 'schedule'
})
export class SchedulePipe implements PipeTransform {
  toTimeString(date: Date) {
    return date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
  }

  toString(date: Date, time: Date): string {
    if (date && time) {
      return `${date.toLocaleDateString()} ${this.toTimeString(time)}`;
    }
    if (date) {
      return `${date.toLocaleDateString()}`;
    }
    if (time) {
      return `${this.toTimeString(time)}`;
    }
    return '';
  }

  isDateEqual(date1: Date, date2: Date): boolean {
    if (!date1 && !date2) {
      return true;
    }
    if (!date1 || !date2) {
      return false;
    }
    return  date1.getFullYear() === date2.getFullYear()
      && date1.getMonth() === date2.getMonth()
      && date1.getDate() === date2.getDate();
  }

  transform(schedule: Schedule, ...args: unknown[]): string {
    const from = this.toString(schedule.fromDate, schedule.fromTime);
    const toDate = this.isDateEqual(schedule.fromDate, schedule.toDate) ? null : schedule.toDate;
    const to = this.toString(toDate, schedule.toTime);

    if (from && to) {
      return `${from} - ${to}`;
    }
    if (from) {
      return from;
    }
    if (to) {
      return to;
    }
    return '';
  }
}
