import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {State} from '../reducers/user.reducer';
import {getSection} from '../selectors/userSettings.selectors';
import {setSection, setAllSections} from '../actions/usersettings.actions';

class Section {
  id: string;
  name: string;
}

@Component({
  selector: 'app-section-settings',
  templateUrl: './section-settings.component.html',
  styleUrls: ['./section-settings.component.css']
})
export class SectionSettingsComponent implements OnInit {
  faculties: Section[] = [
    {id: 'FG_AM', name: 'FG Additive Manufacturing'},
    {id: 'FG_Aero', name: 'FG Aerospace'},
    {id: 'FAB-OCH', name: 'FG Architektur und Bau Ostschweiz'}, // keine events
    {id: 'FAB-ZCH', name: 'FG Architektur und Bau Zentralschweiz'}, // keine events
    {id: 'FAB-ZH', name: 'FG Architektur und Bau Zürich'}, // keine events
    {id: 'FBI-CH', name: 'FG Betriebsingenieure CH'},
    {id: 'DDA', name: 'FG Design and Development Association'},
    {id: 'FAEL', name: 'FG Elektronik und Informatik (FAEL)'},
    {id: 'SE-EXP', name: 'FG Expertenkammer'},
    {id: 'GEO+ING', name: 'FG Geomatik Ingenieure Schweiz'}, // keine events
    {id: 'FG K+K', name: 'FG Karriere und Kommunikation'}, // testen fixen
    {id: 'FGKS', name: 'FG Kunststofftechnik'}, // keine events
    {id: 'FGLiSc', name: 'FG Life Science'},
    {id: 'FGM', name: 'FG Marketing'},
    {id: 'SE-CONS', name: 'FG SE Consultants'}, // keine events
    {id: 'FGST', name: 'FG Sicherheitstechnik'}, // keine events
    {id: 'ste', name: 'FG Swiss Timber Engineers'}, // keine events
    {id: 'UTE', name: 'FG Umwelttechnik und Energie'},
    {id: 'VWI', name: 'FG Vereinigung Wirtschaftsingenieure'}, // keine events
    {id: 'FGYSE', name: 'FG Young Swiss Engineers'}, // keine events
    {id: 'GPA-SO', name: 'GP Architectes'}, // keine events
    {id: 'GPI', name: 'GP Ing. génie civil-Vaud'}, // keine events
    {id: 'GPEI', name: 'GP Electronique et Informatique'}, // keine events
    {id: 'GPMA', name: 'GP Management'}, // keine events
    {id: 'GITI', name: 'GP Technologies de l\'information'}, // keine events
    {id: 'GIA', name: 'GP Ingenieri Aziendali'}, // keine events
    {id: 'INT_SE', name: 'FG International Swiss Engineers'} // keine events
  ];
  regionalSections: Section[] = [
    {id: 'SE-AG', name: 'Sektion Aargau'},
    {id: 'SE-BS', name: 'Sektion Basel'},
    {id: 'SE-BVS', name: 'Section Bas-Valais'}, // keine events
    {id: 'SE-BE', name: 'Sektion Bern'},
    {id: 'SE-BEO', name: 'Sektion Berner Oberland'},
    {id: 'SE-Biel', name: 'Sektion Biel/Bienne'}, // keine events
    {id: 'SE-FR', name: 'Section Fribourg'},
    {id: 'SE-GE', name: 'Section Genève'}, // keine events
    {id: 'SE-GL', name: 'Sektion Glarnerland'},
    {id: 'SE-GR', name: 'Sektion Graubünden'},
    {id: 'SE-NE', name: 'Section Neuchâteloise'}, // keine events
    {id: 'SE-OB_EM', name: 'Sektion Oberaargau / Emmental'}, // keine events
    {id: 'SE-OVS', name: 'Sektion Oberwallis'}, // keine events
    {id: 'SE-Olt', name: 'Sektion Olten'}, // keine events
    {id: 'SE-SO', name: 'Sektion Solothurn'},
    {id: 'SE-SG', name: 'Sektion St. Gallen'}, // keine events
    {id: 'SE-TI', name: 'Sezione Ticino'},
    {id: 'SE-TG', name: 'Sektion Thurgau'}, // keine events
    {id: 'SE-JU', name: 'Section Transjura'},
    {id: 'SE-VAUD', name: 'Section Vaud'}, // keine events
    {id: 'SE-Wil', name: 'Sektion Wil und Umgebung'},
    {id: 'SE-WinSH', name: 'Sektion Winterthur-Schaffhausen'}, // keine events
    {id: 'SE-ZCH', name: 'Sektion Zentralschweiz'}, // keine events
    {id: 'SE-ZH', name: 'Sektion Zürich'},
    {id: 'SE-ZHSee', name: 'Sektion Zürisee'}
  ];
  allSections: Section[] =
    [
      ...this.faculties,
      ...this.regionalSections
    ];
  sections: Section[] = this.allSections;
  section$: Observable<string>;

  constructor(
  private store: Store<State>
  ) {
  }

  ngOnInit(): void {
    this.section$ = this.store.pipe(select(getSection));
  }

  setSection(section: string): void {
    this.store.dispatch(setSection({section}));
  }

  setAllSections(): void {
    this.store.dispatch(setAllSections());
  }

  setFilter(event: any): void {
    const text = event.target.value.toLowerCase();

    this.sections = this.allSections.filter(curr => curr.name.toLocaleLowerCase().indexOf(text) >= 0);
  }

}
