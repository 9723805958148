import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {State} from '../../reducers/user.reducer';
import {login, logout} from '../../actions/user.actions';
import {Observable} from 'rxjs';
import {getLoggedIn, getLoggingIn, getLoginFailure, getUser} from '../../selectors/user.selectors';
import {User} from '../../models/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  error: string;

  loggedIn$: Observable<boolean>;
  user$: Observable<User>;
  loggingIn$: Observable<boolean>;
  loginFailure$: Observable<any>;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<State>
  ) {
    // redirect to home if already logged in
    /*
     *if (this.authenticationService.currentUserValue) {
     *  this.router.navigate(['/']);
     *}
     */
  }

  ngOnInit(): void {
    this.loggedIn$ = this.store.pipe(select(getLoggedIn));
    this.user$ = this.store.pipe(select(getUser));
    this.loggingIn$ = this.store.pipe(select(getLoggingIn));
    this.loginFailure$ = this.store.pipe(select(getLoginFailure));

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.returnUrl = this.route.snapshot.paramMap.get('returnUrl') || '/';
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    const username = this.f.username.value;
    const password = this.f.password.value;

    this.store.dispatch(login({username, password}));
  }

  logout() {
    this.store.dispatch(logout());
  }
}
