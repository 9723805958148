<div class="overlay">
  <div class="center">
    <mat-progress-spinner
      diameter=50
      mode="indeterminate"
      color="primary"
    >
    </mat-progress-spinner>
  </div>
</div>
