import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUser from '../reducers/user.reducer';

export const selectUserState = createFeatureSelector<fromUser.State>(
  fromUser.userFeatureKey
);

export const getLoggedIn = createSelector(selectUserState, state => state.loggedIn);
export const getLoggingIn = createSelector(selectUserState, state => state.loggingIn);
export const getLoginFailure = createSelector(selectUserState, state => state.loginFailure);
export const getUser = createSelector(selectUserState, state => state.user);
