<div class="accentStripe"></div>
<app-toolbar></app-toolbar>
<div class="centerLayout">
  <router-outlet></router-outlet>
</div>
<!--
<button mat-raised-button color="primary">hi</button>
<button mat-raised-button color="accent">hi</button>
<button mat-raised-button color="warn">hi</button>
-->
