import {createReducer, on} from '@ngrx/store';

import { Event } from '../models/event';
import {
  loadEvents,
  loadEventsSuccess,
  loadEvent,
  loadEventSuccess,
  registerEventSuccess, registerEvent, unRegisterEvent, unRegisterEventSuccess
} from '../actions/events.actions';

export const eventsFeatureKey = 'events';

export interface State {
  events: Event[];
  loading: boolean;
}

export const initialState: State = {
  events: [],
  loading: false
};

const eventReducer = createReducer(initialState,
  on(loadEvents, state => ({...state, loading: true})),
  on(loadEventsSuccess, (state, {events}) => ({events, loading: false})),
  on(loadEvent, state => ({...state, loading: true})),
  on(loadEventSuccess, (state, {event}) => {
    const events = [
      ...state.events.filter(cur => cur.id !== event.id),
      event
    ];

    return {...state, events, loading: false};
  }),
  on(registerEvent, state => ({...state, loading: true})),
  on(registerEventSuccess, state => ({...state, loading: false})),
  on(unRegisterEvent, state => ({...state, loading: true})),
  on(unRegisterEventSuccess, state => ({...state, loading: false})),
);

export function reducer(state, action) {
  return eventReducer(state, action);
}
