import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {User} from '../../models/user';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {catchError, map, retry} from 'rxjs/operators';
import {SettingsService} from '../../services/settings.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private settingsService: SettingsService,
    private http: HttpClient
  ) { }

  private static errorHandler(error: HttpErrorResponse): Observable<any> {
    console.error('Fehler aufgetreten!');
    return throwError(error);
  }

  login(username: string, password: string): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: 'Basic ' + btoa(`${username}:${password}`)
      })
    };
    const user: User = { name: username, password};

    return this.http.get<any>(`${this.settingsService.settings.userApiUrl}`, httpOptions)
      .pipe(
        retry(3),
        map(() => user),
        catchError(UserService.errorHandler)
      );
  }

}

