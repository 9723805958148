<mat-card>
  <mat-card-title>{{ event.title }}</mat-card-title>
  <mat-card-content>
    {{ event.location | location: true }}<br/>
    {{ event.schedule | schedule }}<br/>
  </mat-card-content>
  <mat-card-actions>
    <div class="grid-container">
      <div>
        <div
          *ngIf="event.registrationStatus === 'registered'"
          class="registration-status-item"
        >
          <mat-icon style="color: green">check</mat-icon>
          <span style="color: green">Ich nehme teil!</span>
        </div>
        <div
          *ngIf="event.registrationStatus === 'unregistered'"
          class="registration-status-item"
        >
          <mat-icon>close</mat-icon>
          <span>Ich habe mich abgemeldet!</span>
        </div>
      </div>
      <div style="text-align: end">
        <button mat-button>DETAILS</button>
      </div>
    </div>
  </mat-card-actions>
</mat-card>
