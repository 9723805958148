import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EventsRoutingModule } from './events-routing.module';
import {EventListComponent} from './event-list/event-list.component';
import {EventListItemComponent} from './event-list-item/event-list-item.component';
import {EventDetailsComponent} from './event-details/event-details.component';
import {SchedulePipe} from './shared/schedule.pipe';
import {LocationPipe} from './shared/location.pipe';
import {DeadlinePipe} from './shared/deadline.pipe';
import {MaterialModule} from '../material/material.module';
import {HelperModule} from '../helper/helper.module';
import { StoreModule } from '@ngrx/store';
import * as fromEvents from './reducers/events.reducer';
import { EffectsModule } from '@ngrx/effects';
import { EventsEffects } from './effects/events.effects';


@NgModule({
  declarations: [
    EventListComponent,
    EventListItemComponent,
    EventDetailsComponent,
    SchedulePipe,
    LocationPipe,
    DeadlinePipe
  ],
  imports: [
    CommonModule,
    MaterialModule,
    EventsRoutingModule,
    HelperModule,
    StoreModule.forFeature(fromEvents.eventsFeatureKey, fromEvents.reducer),
    EffectsModule.forFeature([EventsEffects])
  ]
})
export class EventsModule { }
