export class Host {
  name: string;
  email: string;
}

export class Schedule {
  fromDate: Date;
  fromTime: Date;
  toDate: Date;
  toTime: Date;
}

export class Location {
  name: string;
  street: string;
  zip: string;
  city: string;
}

export class Logo {
  url: string;
}

export class Document {
  name: string;
  url: string;
}

export class Event {
  id: string;
  title: string;
  description: string;
  host: Host;
  schedule: Schedule;
  location: Location;
  logo: Logo;
  documents: Document[];
  price: number;
  membersPrice: number;
  registrationStatus: string;
  registrationDeadline: Date;
}

